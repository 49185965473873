.forkongithub a {
  background: #000;
  color: #fff;
  text-decoration: none;
  font-family: arial, sans-serif;
  text-align: center;
  font-weight: bold;
  padding: 5px 40px;
  font-size: 1rem;
  line-height: 2rem;
  position: relative;
  transition: 0.5s;
}

.forkongithub a:hover {
  background: #c11;
  color: #fff;
}

.forkongithub a::before,
.forkongithub a::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
  height: 1px;
  background: #fff;
}

.forkongithub a::after {
  bottom: 1px;
  top: auto;
}

h2 {
  font-size: 2em;
  margin: 52px 0 40px;
  text-transform: capitalize;
  color: #051c33;
}

h1 {
  font-family: "Lobster", "Josefin Sans", Helvetica, Arial, sans-serif;
  font-size: 4em;
  color: #051c33;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

.is-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.search-form {
  max-width: 460px;
  display: flex;
  margin: 0 auto 32px;
}

.search-form button {
  outline: none;
  border: none;
  padding: 0px 15px;
  border-radius: 0 0.35em 0.35em 0;
  cursor: pointer;
}

.active {
  background-color: #051c33;
  cursor: pointer;
}

input {
  font-size: 1em;
  width: 100%;
  background-color: #edeff0;
  padding: 10px 15px;
  border: 3px solid #d7dbdf;
  border-right: none;
  border-radius: 0.35em 0 0 0.35em;
  outline: none;
}

.main-nav li {
  width: 100%;
  margin-bottom: 1em;
}

.main-nav a {
  display: block;
  background: #051c33;
  border-radius: 3px;
  padding: 5px;
  color: #fff;
}

.main-nav .active,
.main-nav a:hover {
  background-color: #051c33;
}

.photo-container ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.photo-container ul li {
  width: 220px;
  height: 165px;
  overflow: hidden;
  margin-bottom: 15px;
}

.photo-container ul img {
  width: 100%;
  transform: scale(1.25);
  transition: transform 1.25s;
}

.photo-container li:hover img {
  transform: scale(1.65);
}

.photo-container .not-found {
  width: 100%;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #051c33;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.not-found {
  padding-top: 5%;
}

@media only screen and (max-width: 767px) {
  .photo-container li {
    margin: auto;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 960px;
    margin: auto;
  }

  .main-nav ul {
    display: flex;
    justify-content: center;
  }

  .main-nav li {
    margin: 10px;
    width: 100px;
  }
}

@media screen and (min-width: 800px) {
  .forkongithub {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    width: 200px;
    overflow: hidden;
    height: 200px;
    z-index: 9999;
  }

  .forkongithub a {
    width: 200px;
    position: absolute;
    top: 60px;
    right: -60px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8);
  }
}